@import '../../scss/utilities.scss';

.waves {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  @each $category, $color in $category-colors {
    &_#{$category} {
      border-top: 6px solid $color;
      background-image: url('/images/columnist/#{$category}/header-#{$category}-mobile.svg');
      @include media-breakpoint-only(md) {
        background-image: url('/images/columnist/#{$category}/header-#{$category}-tablet.svg');
      }
      @include media-breakpoint-up(lg) {
        background-image: url('/images/columnist/#{$category}/header-#{$category}-desktop.svg');
      }
    }
  }
}
