@import '../../scss/utilities.scss';

.header {
  @include make-row();
}

.header__content {
  @include make-col-ready();
  @include make-col(12);
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;
  }
}

.header__meta {
  margin-left: $grid-gutter-width*0.5;
  @include media-breakpoint-up(md) {
    margin-left: $grid-gutter-width;
  }
}

.header__feature {
  font-family: $ue-header-font;
  font-weight: 700;
  font-size: $ue-font-size-4;
  line-height: 1;
  color: $headings-color;
  @include media-breakpoint-up(md) {
    font-size: $ue-font-size-2;
  }
  @include media-breakpoint-up(lg) {
    font-size: $ue-font-size-1;
  }
}

.header__creator {
  font-size: $ue-font-size-9;
  font-family: $ue-subheader-font;
  color: $gray-600;
}

.header__image {
  border-radius: 50%;
  box-shadow: 0px 0px 20px $ue-shadow;
  max-width: 75px;
  width: 75px;
  height: 75px; // IE
  @include media-breakpoint-up(md) {
    max-width: 130px;
    width: 130px;
    height: 130px; // IE
  }
  @include media-breakpoint-up(lg) {
    max-width: 175px;
    width: 175px;
    height: 175px; // IE
  }
}

.header__trademark {
  position: relative;
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: super;
  top: -0.1em;
  @include media-breakpoint-only(md) {
    top: -0.3em;
  }
  @include media-breakpoint-up(lg) {
    top: -0.8em;
    font-size: 2.1rem;
  }
}
