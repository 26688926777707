@import '../../../scss/utilities';

@mixin overlay-element {
  content: '';
  position: absolute;
  z-index: $zindex-sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none; // don't block clicks
}

@mixin horizontal-scroller {
  // stylelint-disable-next-line declaration-no-important
  flex-wrap: nowrap !important; // override
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  // stylelint-disable plugin/no-unsupported-browser-features
  // The irony that IE doesn't support this is rich
  @supports (-webkit-overflow-scrolling: touch) {
    -webkit-overflow-scrolling: touch;
  }
  // stylelint-enable plugin/no-unsupported-browser-features
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // hide Firefox scrollbars
  -ms-overflow-style: none; // hide IE scrollbars

  // gradients for user feedback on scroll position
  // stylelint-disable plugin/no-unsupported-browser-features
  // IE doesn't like calc for a lot of things, but it should be fine here and a fallback is provided
  $gradient-size-fallback: $grid-gutter-width 100%;
  $gradient-size: calc(#{$grid-gutter-width} + #{$nav-link-padding-x}) 100%;
  // stylelint-enable plugin/no-unsupported-browser-features
  $start-gradient: linear-gradient(
      to left,
      rgba($ue-white, 1) 0%,
      rgba($ue-white, 1) 50%,
      rgba($ue-white, 0.8) 75%,
      rgba($ue-white, 0) 100%
    )
    right no-repeat;
  $end-gradient: linear-gradient(
      to right,
      rgba($ue-white, 1) 0%,
      rgba($ue-white, 1) 50%,
      rgba($ue-white, 0.8) 75%,
      rgba($ue-white, 0) 100%
    )
    left no-repeat;

  // place the gradients
  &--start:before {
    @include overlay-element();
    background: $start-gradient;
    background-size: $gradient-size-fallback;
    background-size: $gradient-size;
  }
  &--middle:before {
    @include overlay-element();
    background: $start-gradient, $end-gradient;
    background-size: $gradient-size-fallback, $gradient-size-fallback;
    background-size: $gradient-size, $gradient-size;
  }
  &--end:before {
    @include overlay-element();
    background: $end-gradient;
    background-size: $gradient-size-fallback;
    background-size: $gradient-size;
  }
}

.secondary {
  // adjust for nav item padding
  margin-left: -$nav-link-padding-x;
  margin-right: -$nav-link-padding-x;
  @include horizontal-scroller();
}

.secondary__link {
  font-size: $ue-font-size-9;
  font-family: $ue-subheader-font;
  color: $ue-black;
  text-transform: capitalize;
  position: relative; // to position the very fancy underline

  // reserve space for bolded font on hover/active
  // this prevents the rest of the links from moving around
  $bold-weight: 700;
  text-align: center;
  &:before {
    display: block;
    content: attr(data-title);
    font-weight: $bold-weight;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:focus {
    // stylelint-disable plugin/no-unsupported-browser-features
    // stylelint-disable declaration-no-important
    outline-offset: 0 !important;
    // stylelint-enable declaration-no-important
    // stylelint-enable plugin/no-unsupported-browser-features
  }

  @each $category, $color in $category-colors {
    // current page feedback
    &--#{$category} {
      &.active {
        color: $color;
        font-weight: $bold-weight;
      }

      // hover feedback
      &:hover {
        color: $ue-black;
        font-weight: $bold-weight;
      }

      // Very Fancy Underline, but not when it's .active
      &:not(.active, .secondary__link--#{$category}--polls):after {
        // prevent the underline from being wider than the text
        content: '';
        position: absolute;
        left: $nav-link-padding-x;
        // stylelint-disable plugin/no-unsupported-browser-features
        // IE doesn't like calc for a lot of things, but it should be fine here and a fallback is provided
        width: 100%;
        width: calc(100% - #{$nav-link-padding-x * 2});
        // stylelint-enable plugin/no-unsupported-browser-features

        // underline
        bottom: 0;
        border-bottom: 2px solid $color;
        transition: border-bottom ease-in-out 0.2s;
      }

      &:hover:after {
        // VFU grows up on hover
        border-bottom: 6px solid $color;
      }

      &:hover:active:after {
        // click feedback with the VFU
        border-bottom: 6px solid $ue-black;
        transition: none; // clicks are fast, kill the animation
      }

      // Polls link

      // border around polls link

      &--polls {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid $color;
          border-radius: $rounded-pill;
          cursor: pointer;
          transition: border ease-in-out 0.2s;
        }

        &:hover:after {
          border: 6px solid $color;
          border-radius: $rounded-pill;
        }

        &:hover:active:after {
          // click feedback with the VFU
          border: 6px solid $ue-black;
          border-radius: $rounded-pill;
          transition: none; // clicks are fast, kill the animation
        }
      }
    }
  }
  &:active {
    // click feedback
    color: $ue-black;
  }
}
