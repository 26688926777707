@use "sass:math";

@import '../custom';

// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter * 0.5;
  padding-left: $gutter * 0.5;
  margin-right: auto;
  margin-left: auto;
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * 0);
  margin-right: calc(-0.5 * #{$gutter});
  margin-left: calc(-0.5 * #{$gutter});
}

@mixin make-col-ready() {
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we set the width
  // later on to override this initial width.
  flex-shrink: 0;
  width: 100%;
  max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
  padding-right: calc(#{$grid-gutter-width} * 0.5);
  padding-left: calc(#{$grid-gutter-width} * 0.5);
  margin-top: 0;
}

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    $number: math.div($size, $columns);
    width: percentage($number);
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

// make-container-max-widths from bootstrap's scss/mixins/grid.scss
// was deprecated on bootstrap v4.5.1.
// For each breakpoint, define the maximum width of the container in a media query.
@mixin make-container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
